import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { Routes, RouterModule } from "@angular/router";

import { AdminLayoutComponent } from "./layouts/admin-layout/admin-layout.component";
import { LoginComponent } from "./login/login.component";
import { AuthGuardService } from "./guards/auth-guard.service";
import {
  redirectUnauthorizedTo,
  redirectLoggedInTo,
  AngularFireAuthGuard,
  canActivate
} from "@angular/fire/auth-guard";
import { LivePreviewComponent } from "./live-preview/live-preview.component";
const redirectUnauthorizedToLanding = redirectUnauthorizedTo(["login"]);

const routes: Routes = [
  { path: "", redirectTo: "login", pathMatch: "full" },
  {
    path: "login",
    component: LoginComponent,
    ...canActivate(redirectLoggedInTo(["/app"]))
  },
  {
    path: "live",
    component: LivePreviewComponent
  },
  // {
  //   path: '',
  //   redirectTo: 'dashboard',
  //   pathMatch: 'full',
  // },
  {
    path: "app",
    component: AdminLayoutComponent,
    children: [
      {
        path: "",
        loadChildren:
          "./layouts/admin-layout/admin-layout.module#AdminLayoutModule"
      }
    ],
    ...canActivate(redirectUnauthorizedToLanding)
  }
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes, {
      useHash: true
    })
  ],
  exports: []
})
export class AppRoutingModule {}
