import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpModule } from "@angular/http";
import { RouterModule } from "@angular/router";

import { AppRoutingModule } from "./app.routing";
import { ComponentsModule } from "./components/components.module";

import { AppComponent } from "./app.component";
import { TimeAgoPipe } from "time-ago-pipe";

import { AgmCoreModule } from "@agm/core";
import { AdminLayoutComponent } from "./layouts/admin-layout/admin-layout.component";
import {
  LocationStrategy,
  Location,
  PathLocationStrategy
} from "@angular/common";
import { AngularFireModule } from "@angular/fire";
import { AngularFirestoreModule } from "@angular/fire/firestore";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { environment } from "../environments/environment";
import { LoginComponent } from "./login/login.component";
import {
  MatButtonModule,
  MatRippleModule,
  MatFormFieldModule,
  MatInputModule
} from "@angular/material";
import { AngularFireAuthGuard } from "@angular/fire/auth-guard";
import { NgxDaterangepickerMd } from "ngx-daterangepicker-material";
import { LivePreviewComponent } from "./live-preview/live-preview.component";
import { NgxEchartsModule } from "ngx-echarts";

@NgModule({
  imports: [
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpModule,
    ComponentsModule,
    RouterModule,
    AppRoutingModule,
    MatButtonModule,
    MatRippleModule,
    MatFormFieldModule,
    MatInputModule,
    AgmCoreModule.forRoot({
      apiKey: "YOUR_GOOGLE_MAPS_API_KEY"
    }),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule, // imports firebase/firestore, only needed for database features
    AngularFireAuthModule, // imports firebase/auth, only needed for auth features
    NgxDaterangepickerMd.forRoot(),
    NgxEchartsModule
  ],
  declarations: [
    TimeAgoPipe,
    AppComponent,
    AdminLayoutComponent,
    LoginComponent,
    LivePreviewComponent
  ],
  providers: [
    Location,
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    AngularFireAuthGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
