import { Component, OnInit } from "@angular/core";
import { environment } from "environments/environment";
import { AngularFirestore } from "@angular/fire/firestore";

@Component({
  selector: "app-live-preview",
  templateUrl: "./live-preview.component.html",
  styleUrls: ["./live-preview.component.scss"]
})
export class LivePreviewComponent implements OnInit {
  satisfiedCallers: number = 0;
  unsatisfiedCallers: number = 0;
  unRespondedCallers: number = 0;
  todayCalls: number = 0;
  rejectedCalls: number = 0;
  closureCalls: number = 0;
  activeCalls: Array<any> = [];
  options: any;

  constructor(private firestore: AngularFirestore) {}

  ngOnInit(): void {
    // this.firestore.collection("users").get().subscribe(querySnapshot => {
    //   this.usersCount = querySnapshot.size;
    // })
    let start = new Date();
    start.setHours(0, 0, 0, 0);
    const startTime = start.getTime();
    var end = new Date();
    end.setHours(23, 59, 59, 999);
    const endTime = end.getTime();

    this.firestore
      .collection(`/${environment.collection_prefix}calls`, ref => {
        return ref.orderBy("utcNow", "desc");
      })
      .snapshotChanges()
      //.get()
      .subscribe(querySnapshot => {
        this.activeCalls = [];
        //this.usersCount = querySnapshot.size;
        querySnapshot.forEach(docSnapshot => {
          //if (docSnapshot.exists) {
          this.activeCalls.push(docSnapshot.payload.doc.data());
          //}
        });
      });
    this.firestore
      .collection(`/${environment.collection_prefix}logs`)
      .get()
      .subscribe(querySnapshot => {
        querySnapshot.forEach(docSnapshot => {
          if (docSnapshot.exists) {
            const callLog = docSnapshot.data();
            // console.log(callLog.utcNow, startTime);
            if (callLog.utcNow > startTime) {
              this.todayCalls++;
              if (callLog.response === "rejected") {
                this.rejectedCalls++;
              }
              if (callLog.call_closure_on) {
                this.closureCalls++;
              }
              if (callLog.closure_response === "yes") {
                this.satisfiedCallers++;
              }
              if (callLog.closure_response === "no") {
                this.unsatisfiedCallers++;
              }
            }
          }
        });
        this.setupChart();
      });
  }
  setupChart() {
    this.options = {
      legend: {
        data: ["Satisfied", "Unsatisfied"],
        align: "left"
      },
      tooltip: {},
      // xAxis: {

      // },
      // yAxis: {},
      series: [
        {
          name: "Customer Satisfaction",
          type: "pie",
          itemStyle: {
            normal: {
              label: {
                show: true,
                position: "inner",
                formatter: function(params) {
                  return params.value + "\n";
                },
                textStyle: {
                  fontSize: "30",
                  fontWeight: "bold"
                }
              },
              labelLine: {
                show: false
              }
            },
            emphasis: {
              label: {
                show: true,
                position: "center",
                textStyle: {
                  fontSize: "45",
                  fontWeight: "bold"
                }
              }
            }
          },
          data: [
            {
              name: "Satisfied",
              value: this.satisfiedCallers,
              itemStyle: { color: "#43a047" }
            },
            {
              name: "Unsatisfied",
              value: this.unsatisfiedCallers,
              itemStyle: { color: "#e53935" }
            }
          ],
          animationDelay: function(idx) {
            return idx * 10;
          }
        }
      ],
      animationEasing: "elasticOut",
      animationDelayUpdate: function(idx) {
        return idx * 5;
      }
    };
  }
}
