// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  collection_prefix: "demo_",
  firebase: {
    apiKey: "AIzaSyCT8Gj9iM9DzIf8eBZ0aAFF2P_sRThVOWU",
    authDomain: "emt-smart-bands.firebaseapp.com",
    databaseURL: "https://emt-smart-bands.firebaseio.com",
    projectId: "emt-smart-bands",
    storageBucket: "emt-smart-bands.appspot.com",
    messagingSenderId: "489003669567",
    appId: "1:489003669567:web:6e003b753ccc66b9e0408a"
  }
};
